
<template>
  <vx-card>
    <vs-row vs-w="12" style="heigth:100px;">
      <vs-col
        style="padding-right:0; "
        vs-type="flex"
        vs-align="center"
        vs-lg="6"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row vs-w="12">
          <!-- <vs-col
            vs-lg="3"
            vs-sm="3"
            vs-xs="12"
            vs-align="center"
            style="padding: 8px 10px;  vertical-align: middle; font-size:14px;"
          >
            <span>เปิดรายการเช่า :</span>
          </vs-col>-->
          <vs-col
            vs-align="center"
            style="padding: 8px 10px;  vertical-align: middle; font-size:16px; font-weight: bold;"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
          >{{ thisordno(this.orderNumber) }}</vs-col>
          <vs-col vs-type="flex" vs-align="flex-end" vs-lg="6" vs-sm="6" vs-xs="12">
            <vs-input
              autofocus
              style="width:100%;"
              icon-after="true"
              icon="shopping_cart"
              label-placeholder
              ref="addcart"
              @click="setSelect($event)"
              v-model="addtocart"
            />
          </vs-col>
        </vs-row>
        <!-- Need : branchRadio , branches   -->
        <!-- <vs-row vs-w="12">
          <ul v-if="$acl.check('admin')" class="demo-alignment">
            <li v-for="(b, index) in $store.state.auth.myBranches" :key="index" class="px-1">
              <vs-radio v-model="branchRadio" :vs-value="b.branchId">{{ b.branchName }}</vs-radio>
            </li>
          </ul>
        </vs-row> -->
      </vs-col>
      <vs-col
        v-if="$acl.check('admin')"
        style="padding-left:0;"
        vs-type="flex"
        vs-align="flex-start"
        vs-lg="2"
        vs-sm="2"
        vs-xs="12"
      >
        <vs-button
          href="/backoffice/rental"
          target="blank"
          style="width:100%; padding: 10px 0;"
          color="primary"
          type="filled"
        >เปิดรายการใหม่</vs-button>
        <br />
      </vs-col>
      <vs-col
        v-else
        style="padding-left:0; margin-top:10px;"
        vs-type="flex"
        vs-align="flex-start"
        vs-lg="2"
        vs-sm="2"
        vs-xs="12"
      >
        <vs-button
          href="/backoffice/rental"
          target="blank"
          style="width:100%; padding: 10px 0;"
          color="primary"
          type="filled"
        >เปิดรายการใหม่</vs-button>
      </vs-col>
      <vs-col
        style="padding-left:0;"
        vs-type="flex"
        vs-align="center"
        vs-lg="4"
        vs-sm="12"
        vs-xs="12"
      >
        <div class="grid-layout-container alignment-block" style="width:100%;">
          <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12">
            <!-- <vs-col vs-w="2"></vs-col> -->
            <vs-col
              style="color:#3545B7; font-weight:bold; font-size: 18px; text-align:right;"
              vs-w="5"
            >ราคาเช่าสินค้า :</vs-col>
            <vs-col
              style="color:#3545B7; font-weight:bold; font-size: 18px;"
              vs-w="6"
            >{{ formatPrice(rentalPriceTotal) }} บาท</vs-col>
          </vs-row>
          <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12">
            <vs-col
              style="color:#30B717; font-weight:bold; font-size: 18px; text-align:right;"
              vs-w="5"
            >เงินประกัน :</vs-col>
            <vs-col
              style="color:#30B717; font-weight:bold; font-size: 18px;"
              vs-w="6"
            >{{ formatPrice(bailTotal) }} บาท</vs-col>
          </vs-row>
          <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12">
            <vs-col
              style="color:#4c4c4c; font-weight:bold; font-size: 18px; text-align:right;"
              vs-w="5"
            >ยอดเงินรวม :</vs-col>
            <vs-col
              style="color:#4c4c4c; font-weight:bold; font-size: 18px;"
              vs-w="6"
            >{{ formatPrice(grandTotal) }} บาท</vs-col>
          </vs-row>

          <vs-row vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="12"
            >
              <img
                v-if="this.pro === '1'"
                style="width:120px;"
                :src="$store.state.defaultStore + 'buy1get1.png'"
              />
              <img
                v-else-if="this.pro === '2'"
                style="width:120px;"
                :src="$store.state.defaultStore + 'buy2get1.png'"
              />
              <img
                v-else-if="this.pro === '3'"
                style="width:180px;"
                :src="$store.state.defaultStore + 'step_discount.png'"
              />
              <div
                v-if="this.pro === '3'"
                style="position:absolute; color: white; font-weight:bold; font-size: 10px; margin-left: 60px; "
              >เช่า {{ proDetail.startAmount }} ลด {{ proDetail.discount }} %</div>
              <!-- <img v-else style="width:120px;" :src="$store.state.defaultStore + 'buy2get1.png'" /> -->
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
    </vs-row>

    <p></p>
    <br />
    <!-- ตาราง -->
    <vs-table hoverFlat stripe >
      <template slot="thead">
        <vs-th>รูปตัวอย่าง</vs-th>
        <vs-th style="width:200px;" sort-key="itemName">ชื่อสินค้า</vs-th>
        <vs-th sort-key="reservedQuantity">จำนวน</vs-th>
        <vs-th style="text-align:center; width:15%;" sort-key="rentalPrice">ราคาเช่า</vs-th>
        <vs-th style="text-align:center; width:15%;" sort-key="bail">เงินประกัน</vs-th>
        <vs-th>การจัดการ</vs-th>
      </template>

      <template >

        <vs-tr :key="indextr" v-for="(tr, indextr) in products">
          <vs-td>
            <img
              class="responsive rounded"
              :src="tr.mainImage"
              alt="user-upload"
              style="width:100px;"
            />
            <br />
            <div style="width:70%;" v-show="tr.notAvailable" class="notAvailable">ติดคิว</div>
          </vs-td>

          <vs-td >{{ products[indextr].name}}<br><span style="font-size: 0.8rem;">({{ products[indextr].code }})</span></vs-td>

          <vs-td >
            <div class="centerx" style="margin-left:auto !important; margin-right: auto !important;">
              <!--
                <vs-input-number
                  style="width:50px; color: red;"
                  min="1"
                  max="5"
                  v-model="products[indextr].reservedQuantity"
                /> -->
              <!-- {{products[0].reservedQuantity}} -->
              <div @click="minusTestNum(indextr)" style="display: inline-block; cursor: pointer ; width:20px; height: 20px; text-align:center; background-color: #7367F0; color: white;"> - </div>
              <div style="text-align: center; width:40%; height: 20px; display: inline-block;"> {{products[indextr].reservedQuantity}}</div>
                <div @click="addTestNum(indextr)" style="display: inline-block;   cursor: pointer ;   width:20px; height: 20px; text-align:center; background-color:  #7367F0;color: white;"> + </div>
                <!-- <vs-input-number min="13" max="21"  v-model="products[indextr].reservedQuantity" /> -->
              </div>
          </vs-td>

          <vs-td >
            <span style="padding:14px 10px;">
              {{
              formatPrice(
              products[indextr].rentalPrice * products[indextr].reservedQuantity
              )
              }}
            </span>
          </vs-td>

          <vs-td >
            <span style="padding:14px 10px;">
              {{
              formatPrice(products[indextr].bail * products[indextr].reservedQuantity)
              }}
            </span>
          </vs-td>

          <vs-td>
            <i
              @click="removeItem(indextr)"
              class="vs-icon notranslate icon-scale vs-button--icon material-icons null"
              style="order: 0;text-align:left;  font-size:25px; color:red;"
            >delete_forever</i>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <!-- <prism language="js" class="rounded-lg">{{ selected }}</prism> -->
    <vs-row vs-w="12" style="margin-top:30px;  ">
      <vs-col style="padding-right:0; " vs-type="flex" vs-align="center" vs-w="9">
        <vs-row vs-w="12">
          <vs-col
            vs-lg="3"
            vs-md="3"
            vs-xs="3"
            vs-align="center"
            style="padding: 8px 10px;  vertical-align: middle; font-size:14px;"
          >
            <span>กำหนดวันรับ-คืนชุด :</span>
          </vs-col>
          <vs-col vs-lg="6" vs-md="6" vs-xs="6" class="px-2" style="z-index: 9999;">
            <v-date-picker
              readonly
              style="width:100%;font-size:14px;"
              :locale="{
                id: 'th',
                firstDayOfWeek: 2,
                masks: { L: 'DD/MM/YYYY' }
              }"
              mode="range"
              :min-date='new Date()'
              v-model="rentalDate"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="3"
            class="px-2"
          >
          <vs-button
            @click="checkQueue()"
            style="width:100%; padding: 10px 0;"
            color="success"
            type="filled"
          >เช็คคิวจอง</vs-button>
        </vs-col>
          <!-- <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="3"
            class="px-2"
          >
            <vs-button
              @click="resetDate()"
              style="width:100%; padding: 10px 0;"
              color="warning"
              type="filled"
            >ล้างข้อมูล</vs-button>
          </vs-col> -->

        </vs-row>
      </vs-col>
      <!-- <vs-col style="padding-left:0;" vs-type="flex" vs-align="center" vs-w="2">
        <vs-button
          @click="checkQueue()"
          style="width:100%; padding: 10px 0;"
          color="success"
          type="filled"
        >เช็คคิวจอง</vs-button>
      </vs-col> -->
      <vs-col v-if="activeBtn===true" style="padding-left:0;" vs-type="flex" vs-align="center" vs-w="3">

        <vs-checkbox v-model="checkRentalList">
          <span style="font-weight:bold; color:red;">ยืนยันดำเนินการต่อ</span>
        </vs-checkbox>
      </vs-col>
    </vs-row>

    <!--------------------------------------------------------------------->
    <vs-row vs-w="12" style="margin-top:30px;">
      <div style="width:100%; height:5px; background-color:#7AE7FF;"></div>
    </vs-row>
    <!--------------------------------------------------------------------->
    <div
      v-if="checkRentalList"
      class="grid-layout-container alignment-block"
      style="vertical-align:text-top ;width:100%;"
    >
      <!-- รายละเอียดผู้เช่า  -->

      <vs-row vs-w="12" style="margin-bottom:30px; margin-top:30px;">
        <vs-col vs-align="flex-start" vs-type="flex" vs-lg="2" vs-sm="4" vs-xs="4" class="py-6">
          <h3>รายละเอียดผู้เช่า</h3>
        </vs-col>
        <!-- <vs-col vs-type="flex" vs-align="flex-start" vs-offset="6" vs-lg="3" vs-sm="4" vs-xs="4">
          <vs-input
            icon-pack="feather"
            icon="icon-search"
            label-placeholder="Search"
            class="w-full"
            name="customerInfo"
            v-model="customerInfo"
            style="margin-bottom:20px;"
          />
        </vs-col> -->
      </vs-row>
      <vs-row vs-w="12"></vs-row>

      <!--  เบอร์ติดต่อ -->
      <vs-row class="row-form" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-offset="2"
          vs-lg="2"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >เบอร์ติดต่อ</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="5"
          vs-sm="5"
          vs-xs="6"
        >
          <div class = "grid-layout-container alignment-block " style = "width:100%;" >
            <vs-row vs-w="12">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="10"  vs-sm="10" vs-xs="8" class="px-2" >
                <vx-tooltip text="กดปุ่ม ENTER เพื่อตรวจสอบข้อมูลลูกค้าในฐานข้อมูล" class="w-full" >
                    <vs-input    @keypress.enter="checkCustomerByPhoneNumber()" class="w-full" v-model="customerPhone" />
                </vx-tooltip>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="2"  vs-sm="2" vs-xs="4" class="px-2" >
                  <vx-tooltip text="ตรวจสอบข้อมูลลูกค้าในฐานข้อมูล" >
                    <vs-button  @click="checkCustomerByPhoneNumber()"  color="success" type="filled" icon-pack="feather" icon="icon-user-check"></vs-button>
                    <!-- <vs-button color="primary" type="border" icon-pack="feather" icon="icon-home">Home</vs-button> -->
                  </vx-tooltip>
              </vs-col>
            </vs-row>
          </div>
        </vs-col>
      </vs-row>



      <!-- ชื่อลูกค้า -->
      <vs-row class="row-form" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-offset="2"
          vs-lg="2"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >ชื่อลูกค้า</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="5"
          vs-sm="5"
          vs-xs="6"
        >
          <vs-input class="w-full" v-model="customerName" />

          <!-- <span
            class="text-danger text-sm"
            v-show="errors.has('customerName')"
          >{{ errors.first('customerName') }}</span>-->
        </vs-col>
      </vs-row>

      <!--  ที่อยู่ -->
      <vs-row class="row-form" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-offset="2"
          vs-lg="2"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >ที่อยู่</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="5"
          vs-sm="5"
          vs-xs="6"
        >
          <vs-textarea class="w-full" v-model="customerAddress" />
        </vs-col>
      </vs-row>


      <!--------------------------------------------------------------------->
      <div style="border-top: 1px solid #E3E3E3; margin-bottom:30px; "></div>
      <!--------------------------------------------------------------------->

      <!--  ส่วนลดเงินประกัน -->
      <vs-row class="row-form" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-offset="2"
          vs-lg="2"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >ส่วนลดเงินประกัน</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="3"
          vs-sm="3"
          vs-xs="6"
          class="px-1"
        >
          <!-- <vs-input class="w-full" name="bailDiscount" v-model="bailDiscount"/> -->
          <CurrencyValue class="w-full" v-model="bailDiscount"></CurrencyValue>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="1"
          vs-sm="1"
          vs-xs="2"
          class="px-1"
        >บาท</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="1"
          vs-sm="1"
          vs-xs="2"
          class="px-1"
        >
          <vs-input
            class="w-full"
            @blur="computeBailDiscount($event.target.value)"
            name="bailDiscountPercent"
            @focus="selectAll($event)"
            v-model="bailDiscountPercent"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="1"
          vs-sm="1"
          vs-xs="2"
          class="px-1"
        >%</vs-col>
      </vs-row>

      <!--  ส่วนลดค่าเช่า -->
      <vs-row class="row-form" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-offset="2"
          vs-lg="2"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >ส่วนลดค่าเช่า</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="3"
          vs-sm="3"
          vs-xs="6"
          class="px-2"
        >
          <!-- <vs-input class="w-full" name="rentalDiscount" v-model="rentalDiscount"/> -->
          <CurrencyValue class="w-full" v-model="rentalDiscount"></CurrencyValue>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="1"
          vs-sm="1"
          vs-xs="2"
          class="px-1"
        >บาท</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="1"
          vs-sm="1"
          vs-xs="2"
          class="px-1"
        >
          <vs-input
            @blur="computeRentalDiscount($event.target.value)"
            class="w-full"
            @focus="selectAll($event)"
            name="rentalDiscountPercent"
            v-model="rentalDiscountPercent"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="1"
          vs-sm="1"
          vs-xs="2"
        >%</vs-col>
      </vs-row>

      <!--  ส่วนลดโปรโมชั่น -->
      <vs-row class="row-form" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-offset="2"
          vs-lg="2"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >ส่วนลดโปรโมชั่น</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="3"
          vs-sm="3"
          vs-xs="6"
          class="px-2"
        >
          <vs-input
            style="background-color: #f4f4f4;"
            readonly
            class="w-full"
            name="promotionDiscount"
            v-model="promotionDiscount"
          />
        </vs-col>
      </vs-row>

      <!--  วันรับ -->
      <vs-row class="row-form" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-offset="2"
          vs-lg="2"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >วันรับ</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="3"
          vs-sm="3"
          vs-xs="6"
          class="px-2"
        >
          <b>{{ formatDate(startDate) }}</b>
        </vs-col>
      </vs-row>

      <!--  วันคืน -->
      <vs-row class="row-form" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-offset="2"
          vs-lg="2"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >วันคืน</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="3"
          vs-sm="3"
          vs-xs="6"
          class="px-2"
        >
          <b>{{ formatDate(endDate) }}</b>
        </vs-col>
      </vs-row>

      <!--  วิธีมารับ  -->
      <vs-row class="row-form" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-offset="2"
          vs-lg="2"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >วิธีมารับ</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="3"
          vs-sm="3"
          vs-xs="6"
          class="px-2"
        >
          <ul class="centerx">
            <li>
              <vs-radio v-model="receivingMethod" vs-value="มารับเอง">มารับเอง</vs-radio>
            </li>
            <li>
              <vs-radio v-model="receivingMethod" vs-value="ผ่านเมสเซนเจอร์">ผ่านเมสเซนเจอร์</vs-radio>
            </li>
            <li>
              <vs-radio v-model="receivingMethod" vs-value="ผ่าน EMS / Kerry">ผ่าน EMS / Kerry</vs-radio>
            </li>
          </ul>
        </vs-col>
      </vs-row>
      <!--  โน้ต -->
      <vs-row class="row-form" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-offset="2"
          vs-lg="2"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >โน้ต</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="5"
          vs-sm="5"
          vs-xs="6"
          class="px-2"
        >
          <vs-textarea class="w-full" v-model="remark1" />
        </vs-col>
      </vs-row>

      <!--  วันลงชำระ -->
      <vs-row class="row-form" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-offset="2"
          vs-lg="2"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >วันลงชำระ</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="3"
          vs-sm="3"
          vs-xs="6"
          class="px-2"
        >
          <b>{{ toDate() }}</b>
        </vs-col>
      </vs-row>

      <!--------------------------------------------------------------------->
      <div style="border-top: 1px solid #E3E3E3; margin-bottom:30px; "></div>
      <!--------------------------------------------------------------------->

      <!--  ปุ่ม -->
      <vs-row
        v-if="
          this.orderStatus === '' ||
            this.orderStatus === null ||
            this.orderStatus === undefined
        "
        vs-w="12"
        vs-type="flex"
        vs-justify="center"
      >
        <vs-button @click="create()" color="success" type="filled" :disabled="btnActive" >บันทึกการเช่า</vs-button>
      </vs-row>
      <!--  ปุ่ม -->
      <vs-row
        v-if="
          this.orderStatus === 'บันทึก' ||
            (this.$acl.check('admin') && this.orderStatus === 'รอรับชุด')
        "
        vs-w="12"
        vs-type="flex"
        vs-justify="center"
      >
        <vs-button @click="update()" color="warning" type="filled">บันทึกการเช่าซ้ำ</vs-button>
      </vs-row>
      <!--------------------------------------------------------------------->
    </div>
  </vx-card>
</template>

<script>
import axios from "axios";
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";
import vSelect from "vue-select";

import CurrencyValue from "./Components/CurrencyValue";
import shapeFormat from "../../mixins/shapeFormat.js";
import { setTimeout } from "timers";
import VxTooltip from '../../layouts/components/vx-tooltip/VxTooltip.vue';

const dict = {
  custom: {
    name: {
      required: "ยังไม่ได้ใส่ชื่อลูกค้า"
    }
  }
};

Validator.localize("en", dict);

export default {
  mixins: [shapeFormat],
  data() {
    return {
      allInfo: {
        categories: [],
        colors: [],
        sizes: [],
        textures: []
      },
      filter: {
        date: null,
        keyword: "",
        color: [],
        size: [],
        category: "",
        texture: "",
        displayItems: [15, 30, 60, 90 ,120],
        displayItemSelected: 15
      },
      washingDate: 0,
      shopLineScore: 0,
      itemRecordScore: 0,
      shopInfo: {},
      activeBtn :false,
      testnum: 0,
      btnActive: false,
      // headerConfig: {},
      rentalDate: {},
      thisBranch: {},
      pro: "0",
      proDetail: {},
      ava: [],
      orderid: null,
      orderDetail: [],
      orderHeader: [],
      currentBranchId: "",
      itemsInBranch: [],
      originalList: [],
      branchStock: [],
      // >>>>>>>>>>>>>> for select branch
      branches: [],
      branchRadio: "",
      // >>>>>>>>>>>>>> for select branch
      currentUser: "guest",
      roles: [],
      checked: false,

      // 1. ORDER HEADER
      orderNumber: "",
      orderStatus: "", // 0: darft, 1: saved, 2: waiting for receiving, 3: waiting for returning, 4: waiting for money back, 5: closed
      remark: "",
      remark1: "",

      // 2. CUSTOMER
      customerInfo: "",
      customerName: "",
      customerAddress: "",
      customerPhone: "",
      accountNumber: "",
      accountName: "",
      bankName: "",
      transferContact: "",

      // 3. DELIVERY
      howtoreceive: "มารับเอง", // 1 :มารับเอง, 2: ผ่านเมสเซนเจอร์, 3: ผ่าน EMS / Kerry
      receivingDate: null,
      receivingMethod: "มารับเอง",
      returnDate: null,
      addtocart: "",
      // 4. MONETARY
      rentalPriceTotal: 0,
      bailTotal: 0,
      grandTotal: 0,
      bailDiscount: 0,
      rentalDiscount: 0,
      promotionDiscount: 0,
      rentalDiscountPercent: 0,
      bailDiscountPercent: 0,
      deduction: 0,
      deducingDate: null,

      checkRentalList: false,
      startDate: null,
      endDate: null,
      orderInfo: {
        orderNumber: "ORD19031300042"
      },

      selected: [],
      select_order: { label: "OR1903130042", value: "OR1903130042" },
      order_option: [
        { label: "OR1903130042", value: "OR1903130042" },
        { label: "OR1903130041", value: "OR1903130041" },
        { label: "OR1903130040", value: "OR1903130040" }
      ],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],

      displayItems: [],
      itemsInStock: [],

      customers: []
    };
  },
  components: {
    Prism,
    Datepicker,
    CurrencyValue,
    "v-select": vSelect,
    VxTooltip
  },

  computed: {
    //  *** BEGIN : 2020-11-28 : Search By branches
    getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
    getMyBranches(){
      // console.log('@@@@PICKUP myBranches >>> ',this.$store.getters["auth/myBranches"]);
      return this.$store.getters["auth/myBranches"];
    },
    myPermission(){
      // console.log('@@@@PICKUP myPermission >>> ',this.$store.getters["auth/permission"]);

      return this.$store.getters["auth/permission"];
    },
     //  *** BEGIN : 2020-11-28 : Search By branches
    totalRental: function() {
      var total = 0;
      for (var i = 0; i < this.displayItems.length; i++) {
        total += this.displayItems[i].rentalPrice;
      }
      return total;
    },
    //  *** BEGIN : 2020-04-06 : TAX INFO
    taxInfo(){
      return this.$store.getters["shop/tax"];
    }
  },
  async created() {
    this.orderid = this.$route.params.orderid;

    //*** 2021-04-14 */

    // >>>> MOUNTED

  // >>>>>>> FIND USER ID >>>>>>
    this.currentUser = JSON.parse(localStorage.getItem("username"));
    // console.log('this.currentUser ',this.currentUser);

    var setting = null;
    try {
        setting = await this.$http.post(
            this.$store.state.apiURL + "/api/shopinfo/get-clean-fix-duration",
            {},
            this.$store.state.auth.apiHeader
          );

          // console.log("setting >>> ",setting.data);

          this.washingDate = setting.data.cleanFixDuration;

    } catch (error) {
      // setting = null;

    }


    // var thisBranch = null;
    this.currentBranchId = this.getCurrentBranch.branchId;
    this.thisBranch = this.getCurrentBranch;

     await this.init(this.thisBranch );


  },

  //*** 2021-03-09 : END For ABB RECEIPT

  async mounted() {
    // if (localStorage.getItem('reloaded')) {
    //     // The page was just reloaded. Clear the value from local storage
    //     // so that it will reload the next time this page is visited.
    //     localStorage.removeItem('reloaded');
    // } else {
    //     // Set a flag so that we know not to reload the page twice.
    //     localStorage.setItem('reloaded', '1');
    //     location.reload();
    // }

    // COME BACK To edit this ORDER !!
    if (this.orderid !== null && this.orderid !== undefined) {
      // await this.init(this.thisBranch);
      setTimeout(async () => {
        await this.comeback(this.orderid);
      }, 300);
    } else {
      await this.init(this.thisBranch);
    }

    this.branchRadio = this.thisBranch.branchId;
    // this.branchRadio = this.currentBranchId;

    this.proDetail = await this.whatPro();
    // console.log("@@@ RENTAL MOUNTED  this.proDetail >>> ", this.proDetail);
    this.pro = this.proDetail.code;
    // console.log("@@@ RENTAL MOUNTED   this.pro >>> ", this.pro);

    this.customers = await this.loadCustomer();
    // console.log(
    //   "@@@ RENTAL MOUNTED CUSTOMER  this.loadCustomer >>> ",
    //   this.loadCustomer
    // );

    //*** 2021-03-09 : BEGIN For ABB RECEIPT

     await this.$store.dispatch('shop/fetch');

     this.branchId = this.getCurrentBranch.branchId;
    await this.fetchAllInfo();
    await this.fetchProduct();


    // this.shopInfo = await this.getShopInfo();
    // this.shopLineScore = this.getShopLineScore(this.shopInfo);

    //*** 2021-03-09 : END For getShopLineScore
  },
  methods: {
    async fetchAllInfo() {
      const config = this.$store.state.auth.apiHeader;
      await axios
        .post(
           this.$store.state.apiURL + "/api/productgroup/all-info",
          {},
          config
        )
        .then(res => {
          const data = res.data;

          this.allInfo.categories = data.categories;
          this.allInfo.textures = data.textures;
          this.allInfo.colors = data.colors;
          this.allInfo.sizes = data.sizes;
         
        });
    },
    async fetchProduct() {
      const url = `${this.$store.state.apiURL}/api/v2/GetProductItems`;
      const config = this.$store.state.auth.apiHeader;

      let filterColor = "";
      let filterSize = "";


      this.filter.color.forEach(color => {
        filterColor += `${color.code}|`;
      });

      this.filter.size.forEach(size => {
        filterSize += `${size}|`;
      });

      let filterCategory = "";
      let filterTexture = "";

      if(this.filter.category !== null && this.filter.category !== ""){
        filterCategory = this.filter.category.code;
      }

      if(this.filter.texture !== null && this.filter.texture !== ""){
        filterTexture = this.filter.texture.code;
      }



      await axios
        .post(
          url,
          {
            filter: true,
            showNotAvailable: false,
            branchId: this.getCurrentBranch.branchId,
            simpleSearch: false,
            pageNumber: this.currentPaginate,
            pageSize: this.filter.displayItemSelected,
            color: filterColor,
            size: filterSize,
            category: filterCategory,
            texture: filterTexture,

          },

          config
        )
        .then(res => {
          const data = res.data;
          // console.log(`>>> online-billing mounted`);

          // get total items
          this.totalPaginate = Math.round(
            data.itemNumber / this.filter.displayItemSelected
          );

          // fresh display items
          this.products = [];

          // update path image
          data.itemList.forEach((item, index) => {
            // console.log(item, index);
            if (data.itemList[index].mainImage !== null) {
              const imgURL = `${this.$store.state.imageStore}${item.mainImage}`;
              data.itemList[index]["mainImage"] = imgURL;
              // console.log(imgURL);
            } else {
              data.itemList[index]["mainImage"] =
                "https://images.unsplash.com/photo-1533090161767-e6ffed986c88?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=458&h=550&q=80";
            }
          });

          this.products = data.itemList;

          console.log("this.products >>> ",this.products);

          this.pagination = this.$calcPagination(
            data.itemList.length,
            this.currentPaginate,
            data.itemListNumber,
            data.itemNumber
          );
        });
    },

    async getCustomerInfo(phoneNumber){
      let cust = await this.checkCustomerByPhoneNumber(phoneNumber);
      this.customerInfo.name = cust.customer.name;
      this.customerInfo.address = cust.customer.address;
      this.customerInfo.birthDate = this.format_ddmmyyy(cust.customer.birthDate);
      this.customerInfo.lineId = cust.customer.lineId;
      this.customerInfo.howToKnow = cust.customer.howToKnowShop
      this.customerInfo.voucher =   cust.maxVoucher;
      this.customerInfo.point =   cust.maxPoint;

      console.log("customer info from this.customerInfo ", this.customerInfo)
    },

    // checkCustomerByPhoneNumber(){

    //   var ac = this.customers.find(x => x.phone === this.customerPhone );

    //   // console.log("Customer info >>> ", ac);
    //   if (ac) {
    //     this.customerName = ac.name;
    //     this.customerAddress = ac.address;
    //     this.customerPhone = ac.phone;
    //   }else{
    //     this.$swal({
    //       icon: 'warning',
    //       title: 'ไม่มีข้อมูลลูกค้าในฐานข้อมูล',
    //     });
    //     this.customerName = "";
    //     this.customerAddress = "";
    //   }
    // },
    addTestNum(ind){
      var rev = this.displayItems[ind];
      if(rev.reservedQuantity >= 999)
      {
         return;
      }
      (rev.reservedQuantity)++;
      this.checkRentalList = false;

      this.$set(this.displayItems, ind , rev);
    },

    minusTestNum(ind){
      var rev = this.displayItems[ind];
      if(rev.reservedQuantity <= 1)
      {
         return;
      }
      (rev.reservedQuantity)--;
      this.checkRentalList = false;

      this.$set(this.displayItems, ind , rev);
    },
    resetDate() {
      this.startDate = null;
      this.endDate = null;
      this.rentalDate = {};
    },
    toDate() {
      var dd = new Date();
      return this.formatDate(dd);
    },

    descendingSort(inputList) {
      var list = [];
      for (var i = 0; i < inputList.length; i++) {
        list.push(inputList[i]);
      }
      list.sort((a, b) => b.rentalPrice - a.rentalPrice);
      return list;
    },

    ascendingSort(inputList) {
      var list = [];
      for (var i = 0; i < inputList.length; i++) {
        list.push(inputList[i]);
      }
      list.sort((a, b) => a.rentalPrice - b.rentalPrice);
      return list;
    },

    allQty(inputList) {
      var num = 0;
      for (var i = 0; i < inputList.length; i++) {
        num = num + inputList[i].reservedQuantity;
      }
      return num;
    },
    proDiscount(countItem, inputList) {
      var amount = 0;
      var ind = 0;
      var acc = 0;
      while (acc < countItem) {
        var qtyInLine = inputList[ind].reservedQuantity;

        if (qtyInLine + acc <= countItem) {
          amount = amount + inputList[ind].rentalPrice * qtyInLine;
          acc = acc + qtyInLine;
          // console.log("IN IF คิดหมด >>>> ", qtyInLine);
        } else {
          amount = amount + inputList[ind].rentalPrice * (countItem - acc);
          // console.log("IN ELSE คิดส่วนเดียว >>>>> ", countItem - acc);
          acc = acc + qtyInLine;
        }
        ind = ind + 1;

        // console.log(
        //   " [",
        //   ind,
        //   "] :  qtyInLine  >>>  ",
        //   qtyInLine,
        //   "  acc >>> ",
        //   acc,
        //   "  amount >>> ",
        //   amount
        // );
      }
      return amount;
    },

    buy1get1() {
      var list = [];

      list = this.ascendingSort(this.displayItems);
      var ll = list.filter(
        ss =>
          ss.category !== "F" &&
          ss.category !== "L" &&
          ss.category !== "S" &&
          ss.rentalPrice > 0
      );

      var allqty = this.allQty(ll);

      var countItem = Math.floor(allqty / 2);

      // ใช้ list ที่เรียงจากน้อยไปมาก + จำนวน Item ที่ไม่คิดเงิน
      var discount = this.proDiscount(countItem, ll);

      return discount;
    },

    buy2get1() {
      var list = [];

      list = this.ascendingSort(this.displayItems);
      var ll = list.filter(
        ss =>
          ss.category !== "F" &&
          ss.category !== "L" &&
          ss.category !== "S" &&
          ss.rentalPrice > 0
      );

      var allqty = this.allQty(ll);
      var countItem = Math.floor(allqty / 3);

      // ใช้ list ที่เรียงจากน้อยไปมาก + จำนวน Item ที่ไม่คิดเงิน
      var discount = this.proDiscount(countItem, ll);

      return discount;
    },

    grandtotalDiscount() {
      var discount = 0;
      var list = [];
      list = this.ascendingSort(this.displayItems);
      var ll = list.filter(
        ss =>
          ss.category !== "F" &&
          ss.category !== "L" &&
          ss.category !== "S" &&
          ss.rentalPrice > 0
      );

      var renp = 0;

      for (var i = 0; i < ll.length; i++) {
        renp = renp + ll[i].rentalPrice;
      }

      if (renp >= this.proDetail.startAmount) {
        discount = (renp * this.proDetail.discount) / 100;
      }
      return discount;
    },
    calculatePromotion() {
      if (this.pro === "1") {
        this.promotionDiscount = this.buy1get1();
      } else if (this.pro === "2") {
        this.promotionDiscount = this.buy2get1();
      } else if (this.pro === "3") {
        this.promotionDiscount = this.grandtotalDiscount();
      } else {
        this.promotionDiscount = 0;
      }
    },
    async whatPro() {
      var allpro = await this.loadPro();
      var proobj = {};
      for (var i = 0; i < allpro.length; i++) {
        if (allpro[i].state === true) {
          proobj = await allpro[i];
        }
      }
      return proobj;
    },

    async loadPro() {
      var res = null;
      try {
        res = await this.$http.get(
          this.$store.state.apiURL + "/api/promotion",
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        return null;
      }

      return res.data;
    },

    async loadCustomer() {
      var res = null;
      try {
        res = await this.$http.get(
          this.$store.state.apiURL + "/api/customer",
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        this.noticeError(
          "โหลดข้อมูลลูกค้าไม่สำเร็จ โปรด refresh page อีกครั้ง"
        );
        return null;
      }

      return res.data;
    },

    thisordno(ord) {
      if (ord === null || ord === undefined || ord === "")
        return "ยังไม่มีเลข Order";
      else return ord;
    },
    // date_diff_indays(date1, date2) {
    //   var dt1 = new Date(date1);
    //   var dt2 = new Date(date2);
    //   return Math.floor(
    //     (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
    //       Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
    //       (1000 * 60 * 60 * 24)
    //   );
    // },

    // addDay(date, days) {
    //   const copy = new Date(Number(date));
    //   copy.setDate(date.getDate() + days);
    //   return copy;
    // },
    // >>>>>>> CHECK QUEUE >>>>>>>>>
    async checkQueue() {
      // เช็ค productItemId / this.startDate >=  /  =< this.endDate
      //  ต้องเทียบเป็นวัน
      // เอาจำนวนที่ยืม + จำนวนที่ยืมไปแล้ว <= จำนวนที่มีใน Branch

      // console.log("this.startDate >>> ",this.startDate , " this.endDate >>>> ",this.endDate);

      if (
        this.startDate === null ||
        this.startDate === undefined ||
        this.endDate === null ||
        this.endDate === undefined
      ) {
        this.noticeWarning(
          "ข้อมูลไม่ครบถ้วน",
          "ยังไม่ได้กำหนดวันรับชุด หรือวันคืนชุด"
        );

        return;
      }



      if (this.formatDate(this.startDate) > this.formatDate(this.endDate)) {
        this.noticeWarning(
          "ข้อมูลไม่ถูกต้อง",
          "การกำหนดวันรับชุดต้องอยู่ก่อนวันคืนชุด !!!"
        );

        return;
      }



      if (Object.entries(this.rentalDate).length === 0 || this.rentalDate === null) {
          this.$swal({
            icon: 'warning',
            title: 'ยังไม่ได้กำหนดวันรับ-วันคืนชุด',
          });

          return;
      }

      var temp = this.displayItems;
      if (
        this.displayItems === null ||
        this.displayItems === undefined ||
        this.displayItems.length === 0
      ) {
        this.noticeWarning(
          "ข้อมูลไม่ครบถ้วน",
          "ใส่รหัสสินค้าที่ต้องการตรวจสอบ"
        );
        return;
      }
      // *** BEGIN 2021-01-19
      this.activeBtn = true;
      // *** END 2021-01-19

      var diffDate = this.date_diff_indays(this.startDate, this.endDate);
      diffDate = diffDate + this.washingDate;

      var dlist = await this.loadAllDetail();

      var borlist = await this.$http.get(
        this.$store.state.apiURL + "/api/borrow",
        this.$store.state.auth.apiHeader
      );
      // console.log("dlist >>> ", dlist);

      for(var i = 0; i < temp.length; i++) {
        // Challenge by set notAvailable to FALSE
        temp[i].notAvailable = false;

        var itemId = temp[i].productItemId;
        // console.log("temp[", i, "]  >> ", temp[i].code);

        // item ที่ได้คือ item ใน branch stock
        var item = this.itemsInBranch.find(x => x.productItemId === itemId);
        if (item === null || item === undefined) {
          temp[i].notAvailable = true;
        } else {
          // ถ้าของมีจำนวน 0
          if (item.amountInBranch === 0) {
            temp[i].notAvailable = true;
          }
        }

        // item ที่ได้คือ item ใน branch stock
        // เอาค่า item.amountInBranch
        // ต้องเทียบทีละวัน

        for (var da = 0; da < diffDate + 1; da++) {
          // เริ่มเทียบวันแรก ค่อยๆ เทียบไปทีละวันจนวันสุดท้าย
          // var theDate = this.addDay(new Date(this.startDate), da);
          var theDate = this.addDay(new Date(this.startDate), da);
          theDate = this.formatDate(theDate);
          // console.log("theDate >>> ", theDate);

          // dlist คือ orderDetail List ทั้งหมด
          // state = true คือ queue ที่โดน LOCK อยู่ !!!
          // console.log("dlist :::::: ", dlist);
          // console.log(
          //   "this.thisBranch.branchId  :::::: ",
          //   this.thisBranch.branchId
          // );
          var condition = await dlist.filter(
            f =>
              f.productItemId === temp[i].productItemId &&
              f.orderHeaderId !== this.orderid &&
              this.formatDate(f.startDate) <= theDate &&
              theDate <= this.formatDate(f.wnfReceivingDate) &&
              f.state === true &&
              // เอาแต่เฉพาะสินค้าของ Branch นี้
              f.branchId === this.thisBranch.branchId
          );

          var conditionSum = 0;
          for (var j = 0; j < condition.length; j++) {
            conditionSum = conditionSum + condition[j].reservedQuantity;
          }

          // console.log("[", i, "] conditionSum >>> ", conditionSum);
          // console.log("[", i, "] ReserveQTY >>> ", temp[i].reservedQuantity);
          // console.log(
          //   "[",
          //   i,
          //   "] item.amountInBranch >>> ",
          //   item.amountInBranch
          // );
          // BORROW >>> จำนวนที่ยืมมา
          var borsum = 0;

          // console.log("######## BEFORE ALL I borlist >>>> ", borlist);

          if(borlist.data !== null && borlist.data !== undefined)
          {

            // console.log('borlist.data !==null',borlist.data);

            if(borlist.data.length > 0)
            {
                  var borf = await borlist.data.filter(
                    bf =>
                      this.formatDate(bf.startDate) <= theDate &&
                      theDate <= this.formatDate(bf.endDate) &&
                      bf.fromBranch === this.thisBranch.branchId &&
                      bf.productItemId === temp[i].productItemId
                  );
            }
          }

          // console.log("######## ALL I BORROWED >>>> ", borf);

          var borf_length = 0;
          if(borf === null || borf === undefined){
            borf_length = 0;
          }else{
             borf_length = borf.length;
          }


          for (let inb = 0; inb < borf_length; inb++) {
            var bor = await dlist.filter(
              f =>
                f.productItemId === borf[inb].productItemId &&
                f.orderHeaderId === borf[inb].borrowId &&
                f.state === true &&
                f.flag2 === "b"
            );
            if (bor.length >= 1) {
              borsum = borsum + borf[inb].qty;
            }
            // console.log("######## borsum >>>> ", borsum);
          }

          // BORROW >>> จำนวนที่ยืมมา

          // ค่า reservedQuantity ทั้งหมด + ตัวมันเอง ต้องไม่มากกว่า ของที่มีใน stock
          var allReservedQuantity = temp[i].reservedQuantity + conditionSum;

          if (allReservedQuantity > item.amountInBranch + borsum) {
            temp[i].notAvailable = true;
            // ถ้าไม่ว่างก็ดู item ต่อไปได้เลย
            break;
          }
        }
      }

      // ดูว่า รายการเช่าชุดไปต่อได้มั้ย
      var countValid = [];
      countValid = temp.filter(c => c.notAvailable === true);

      if (
        countValid.length > 0 &&
        countValid !== undefined &&
        countValid !== null
      ) {
        this.checkRentalList = false;
        this.noticeWarning("ตรวจสอบอีกครั้ง", "มีชุดที่คิวไม่ว่าง");
      } else {
        this.checkRentalList = true;
      }

      // update displayItems
      this.displayItems = [];

      for (var x = 0; x < temp.length; x++) {
        this.displayItems.push(temp[x]);
      }

      // console.log("CHECK QUEUE >>>> ", this.displayItems);
    },
    // >>>>>>> CHECK QUEUE >>>>>>>>>

    checkCustomerInfo() {
      // >>>>>>>>>> Validate INFO
      if (
        this.customerName === null ||
        this.customerName === undefined ||
        this.customerName === ""
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่ชื่อลูกค้า");
        return false;
      }
      if (
        this.customerAddress === null ||
        this.customerAddress === undefined ||
        this.customerAddress === ""
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่ที่อยู่ลูกค้า");
        return false;
      }
      if (
        this.customerPhone === null ||
        this.customerPhone === undefined ||
        this.customerPhone === ""
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่เบอร์โทรลูกค้า");
        return false;
      }

      return true;

      // >>>>>>>>>> Validate INFO
    },

    async create() {
      // >>>>>>>>>> Validate INFO
      //   if (  this.startDate === null ||   this.startDate === undefined ||  this.endDate === null ||  this.endDate === undefined  ) {

      //   this.$swal({
      //     icon: 'warning',
      //     title: 'ยังไม่ได้กำหนดวันรับ-วันคืนชุด',
      //   });

      //   return;
      // }

      if(this.rentalDate === null){
        this.$swal({
          icon: 'warning',
          title: 'ยังไม่ได้กำหนดวันรับ-วันคืนชุด',
        });

        return;
      }
      if(this.displayItems.length <= 0){
        this.$swal({
          icon: 'warning',
          title: 'ยังไม่มีข้อมูลสินค้า',
        });

        return;
      }

      if (this.formatDate(this.startDate) > this.formatDate(this.endDate)) {
        this.noticeWarning(
          "ข้อมูลไม่ถูกต้อง",
          "การกำหนดวันรับชุดต้องอยู่ก่อนวันคืนชุด !!!"
        );

        return;
      }
      if (!this.checkCustomerInfo()) {
        return;
      }
      // >>>>>>>>>> Validate INFO
      this.$vs.loading({ type: "radius" });
      // SAVE HEADER

      var bd = isNaN(Number(this.bailDiscount)) ? 0 : Number(this.bailDiscount);
      var rd = isNaN(Number(this.rentalDiscount))
        ? 0
        : Number(this.rentalDiscount);

      var pd = isNaN(Number(this.promotionDiscount))
        ? 0
        : Number(this.promotionDiscount);

      // *** BEGIN : 2021-04-13 NET Grand Total
      // var netTotal = 0;
      // var netTotal = this.rentalPriceTotal_final(this.rentalPrice, rd);

      var theGrandTotal = 0;
      if(this.taxInfo.vatSystem === true)
      {
        theGrandTotal = this.rentalPriceTotal_final(this.rentalPriceTotal, rd) + this.bailTotal - (bd + pd);
      }else{
        theGrandTotal = Number(this.grandTotal) - (bd + rd + pd)
      }

      // *** END : 2021-04-13 NET Grand Total

      // *** BEGIN : 2021-04-14 ADD VAT

      var vat = 0;
      var beforeVat = 0;
      var netPrice = 0;



      if(this.taxInfo.vatSystem === true)
      {
        vat = this.vat_final(this.rentalPriceTotal, rd);
        beforeVat = this.beforeVat_final(this.rentalPriceTotal, rd);
        netPrice = this.rentalPriceTotal_final(this.rentalPriceTotal, rd);
      }else{

        netPrice = this.rentalPriceTotal;
      }





      var ord = {
        // orderHeaderId: "string",
        // orderRef: "string",
        vat : vat,
        beforeVat : beforeVat,
        netPrice : netPrice,
        inVat : this.taxInfo.vatSystem,
        orderNumber: "",

      // *** END : 2021-04-14 ADD VAT

        // orderNumberRef: "string",
        branchId: this.currentBranchId,
        orderStatus: "รอรับชุด",
        remark1: this.remark1,
        // remark2: "string",
        // remark3: "string",
        // remark4: "string",
        customerId: "string",
        customerName: this.customerName,
        customerAddress: this.customerAddress,
        customerPhone: this.customerPhone,
        // accountNumber: "string",
        // accountName: "string",
        // bankName: "string",
        // transferContact: "string",
        receivingMethod: this.receivingMethod,
        pickupDate: this.startDate,
        returnDate: this.endDate,
        rentalPriceTotal: this.rentalPriceTotal,
        bailTotal: this.bailTotal,
        // grandTotal: Number(this.grandTotal) - (bd + rd + pd),
        grandTotal: theGrandTotal,

        rentalDiscount: this.rentalDiscount,
        bailDiscount: this.bailDiscount,
        promotionDiscount: this.promotionDiscount,
        // deduction: 0,
        // deductionDate: "2019-07-14T09:40:20.607Z",
        // bailReturningAmount: 0,
        // bailReturningStatus: "string",
        // bailReturningDate: "2019-07-14T09:40:20.607Z",
        description: "",
        state: true,
        // createDateTime: "",
        createBy: this.currentUser,
        // updateDateTime: "",
        updateBy: this.currentUser
      };

      // >>>> START TO SAVE HEADER
      var responseOrder = null;
      try {
        responseOrder = await this.$http.post(
          this.$store.state.apiURL + "/api/orderheader",
          ord,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        responseOrder = null;
        return;
      }

      if (responseOrder !== null) {
        this.orderHeader = await responseOrder.data;
        setTimeout(() => {
        }, 300);
      } else {
        responseOrder = null;
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError("บันทึกไม่สำเร็จ");
          return;
        }, 300);
      }


      // SAVE DETAIL
      var dlist = [];
      for (var d = 0; d < this.displayItems.length; d++) {
        var detail = {
          orderHeaderId: await this.orderHeader.orderHeaderId,
          branchId: this.currentBranchId,
          productItemId: this.displayItems[d].productItemId,
          // update 2020-11-14
          name: this.displayItems[d].name,
          mainImage: this.displayItems[d].mainImage,
          // update 2020-11-14
          code: this.displayItems[d].code,
          reservedQuantity: this.displayItems[d].reservedQuantity,
          rentalPrice: this.displayItems[d].rentalPrice,
          bail: this.displayItems[d].bail,
          state: true,
          startDate: this.startDate,
          endDate: this.endDate,
          createBy: this.currentUser,
          updateBy: this.currentUser
        };

        dlist.push(detail);
      }

      // >>>> START TO SAVE HEADER

      var responseDetail = null;
      try {
        responseDetail = await this.$http.post(
          this.$store.state.apiURL + "/api/orderdetail",
          dlist,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        responseDetail = null;
        this.$vs.loading.close();
        this.btnActive = true;
      }

      if (responseDetail !== null) {
        setTimeout(() => {

          //*** 2021-03-09 : BEGIN For ABB RECEIPT

          // var weighted_score = this.calculateScoreForAbbReceipt(this.shopLineScore, this.displayItems.length);


          //*** 2021-03-09 : END For ABB RECEIPT

          //*** ABB RECEIPT
          // this.$router.push({
          //   path: "/pages/abb-receipt/" + weighted_score.toString()  + "/" + this.orderHeader.orderHeaderId
          // });

          //*** FULL RECEIPT
          this.$router.push({
            path: "/backoffice/order-detail/" + this.orderHeader.orderHeaderId
          });
        }, 800);
      } else {
        setTimeout(() => {
          this.$vs.loading.close();
        }, 800);
        this.noticeError("บันทึกไม่สำเร็จ");
      }
      this.noticeSuccess("บันทึกสำเร็จ");
    // Disable  บันทึกการเช่า button
      this.btnActive = true;
      this.$vs.loading.close();


      // >>>> START TO SAVE HEADER
    },
    async update() {
      // >>>>>>>>>> Validate INFO
      if (!this.checkCustomerInfo()) {
        return;
      }

      // >>>>>>>>>> Validate INFO
      this.$vs.loading({ type: "radius" });

      // SAVE HEADER

      var bd = isNaN(Number(this.bailDiscount)) ? 0 : Number(this.bailDiscount);
      var rd = isNaN(Number(this.rentalDiscount))
        ? 0
        : Number(this.rentalDiscount);

      var pd = isNaN(Number(this.promotionDiscount))
        ? 0
        : Number(this.promotionDiscount);

      var ord = {
        // orderHeaderId: "string",
        // orderRef: "string",
        orderNumber: this.orderNumber,
        // orderNumberRef: "string",
        branchId: this.currentBranchId,
        orderStatus: "บันทึก",
        remark1: this.remark1,
        // remark2: "string",
        // remark3: "string",
        // remark4: "string",
        customerId: "string",
        customerName: this.customerName,
        customerAddress: this.customerAddress,
        customerPhone: this.customerPhone,
        // accountNumber: "string",
        // accountName: "string",
        // bankName: "string",
        // transferContact: "string",
        receivingMethod: this.receivingMethod,
        pickupDate: this.startDate,
        returnDate: this.endDate,
        rentalPriceTotal: this.rentalPriceTotal,
        bailTotal: this.bailTotal,
        grandTotal: Number(this.grandTotal) - (bd + rd + pd),
        rentalDiscount: this.rentalDiscount,
        bailDiscount: this.bailDiscount,
        promotionDiscount: this.promotionDiscount,
        // deduction: 0,
        // deductionDate: "2019-07-14T09:40:20.607Z",
        // bailReturningAmount: 0,
        // bailReturningStatus: "string",
        // bailReturningDate: "2019-07-14T09:40:20.607Z",
        description: "",
        state: true,
        // createDateTime: "",
        createBy: this.currentUser,
        // updateDateTime: "",
        updateBy: this.currentUser
      };
      // var grandup =
      //   this.grandTotal -
      //   (this.bailDiscount + this.rentalDiscount + this.promotionDiscount);
      // >>>> START TO SAVE HEADER
      var responseOrder;
      try {
        responseOrder = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/" + this.orderid,
          ord,
          this.$store.state.auth.apiHeader
        );
        if (responseOrder.status === 200) {
          this.orderHeader = await responseOrder.data;
        }
      } catch (error) {
        setTimeout(() => {
          this.$vs.loading.close();
        }, 1000);
        this.noticeError(error);
      }

      // SAVE DETAIL
      var dlist = [];
      for (var d = 0; d < this.displayItems.length; d++) {
        var detail = {
          orderHeaderId: await this.orderHeader.orderHeaderId,
          branchId: this.currentBranchId,
          productItemId: this.displayItems[d].productItemId,

          // update 2020-11-14
          name: this.displayItems[d].name,
          mainImage: this.displayItems[d].mainImage,
          // update 2020-11-14

          code: this.displayItems[d].code,
          reservedQuantity: this.displayItems[d].reservedQuantity,
          rentalPrice: this.displayItems[d].rentalPrice,
          bail: this.displayItems[d].bail,
          state: true,
          startDate: this.startDate,
          endDate: this.endDate,
          createBy: this.currentUser,
          updateBy: this.currentUser
        };

        dlist.push(detail);
      }

      // >>>> START TO SAVE HEADER

      var responseDetail;
      try {
        responseDetail = await this.$http.put(
          this.$store.state.apiURL + "/api/orderdetail/" + this.orderid,
          dlist,
          this.$store.state.auth.apiHeader
        );
        if (responseDetail.status === 200) {
          setTimeout(() => {
            this.$vs.loading.close();
            this.$router.push({
              path: "/backoffice/order-detail/" + this.orderHeader.orderHeaderId
            });
          }, 3000);
        }
      } catch (error) {
        setTimeout(() => {
          this.$vs.loading.close();
        }, 1000);
        this.noticeError(error);
      }

      // >>>> START TO SAVE HEADER
    },

    async loadOrder(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderheader/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },

    async loadDetail(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderdetail/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },
    async loadAllDetail() {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderdetail",
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },

    async comeback(orderid) {
      var order = await this.loadOrder(orderid);
      var dlist = await this.loadDetail(orderid);

      console.log("comeback >>> ");

      // var allBranches = await this.loadBranch();

      this.orderNumber = await order.orderNumber;
      this.customerName = await order.customerName;
      this.customerAddress = await order.customerAddress;
      this.customerPhone = await order.customerPhone;
      this.receivingMethod = await order.receivingMethod;
      this.startDate = await order.pickupDate;
      this.endDate = await order.returnDate;
      this.rentalPriceTotal = await order.rentalPriceTotal;
      this.bailTotal = await order.bailTotal;
      this.grandTotal = await order.grandTotal;
      this.rentalDiscount = await order.rentalDiscount;
      this.bailDiscount = await order.bailDiscount;
      this.promotionDiscount = await order.promotionDiscount;
      this.orderStatus = await order.orderStatus;
      this.currentBranchId = await order.branchId;
      this.remark1 = await order.remark1;

      // var thisBranch = await allBranches.find(
      //   a => a.branchId === this.currentBranchId
      // );
      await this.init(this.thisBranch);

      // LOAD DETAIL

      if (dlist !== null && dlist !== undefined && dlist.length > 0) {
        for (var d = 0; d < dlist.length; d++) {
          var anItem = await this.itemsInBranch.find(
            x => x.productItemId === dlist[d].productItemId
          );
          anItem.reservedQuantity = dlist[d].reservedQuantity;
          await this.displayItems.push(anItem);
        }
      }


      console.log("this.displayItems >>> ",this.displayItems);

      await this.checkQueue();
    },

    async init(thisBranch) {
      console.log("@init thisBranch >>>> ", thisBranch);

      this.displayItems = [];
      var allInBranch = await this.loadStock(thisBranch.branchId);
      this.branchStock = await allInBranch;

      // LOAD ALL ITEMS
      var allProduct = await this.loadProduct();
      this.originalList = await allProduct;
      this.itemsInStock = await allProduct;




      // console.log(
      //   "@init thisBranch.branchId  >>>> ",
      //   thisBranch.branchId
      // );
      // console.log(
      //   "@init this.allInBranch >>>> ",
      //   this.allInBranch
      // );

      // console.log(
      //   "@init this.itemsInStock >>>> ",
      //   this.itemsInStock
      // );

      // INITAILIZE ITEMS in BRANCH
      this.itemsInBranch = [];
      for (var i = 0; i < allInBranch.length; i++) {
        var anItem = await allProduct.find(
          x => x.productItemId === allInBranch[i].productItemId
        );

      //   console.log(
      //   "@init ",i,". anItem >>>> ",
      //   this.itemsInStock
      // );

        if (anItem !== null && anItem !== undefined) {
          if (anItem.mainImage) {
            anItem.mainImage =
              (await this.$store.state.imageStore) + anItem.mainImage;
          } else {
            anItem.mainImage =
              (await this.$store.state.imageStore) + "defaultImage.png";
          }



          // ADDITIONAL FIELD
          anItem.amountInBranch = allInBranch[i].inStock;
          anItem.reservedQuantity = 1;
          anItem.notAvailable = false;
          anItem.category = anItem.category;

          this.itemsInBranch.push(anItem);
        }
      }

      // console.log(
      //   "@init this.itemsInBranch >>>> ",
      //   this.itemsInBranch
      // );

    },
    async findCurrentBranch() {
      // >>>>>>> หา branch จาก  role ของ user >>>>>>>>>
      let branch_th = "1";
      this.roles = JSON.parse(localStorage.getItem("roles"));
      var userrole = this.roles.find(x => x.roleName.includes("staff-"));
      if (userrole === null || userrole === undefined) {
        branch_th = "1";
      } else {
        var role_split = userrole.roleName.split("-");
        if (role_split[1] === null || role_split[1] === undefined) {
          branch_th = "1";
        }
        branch_th = role_split[1];
      }

      var allBranches = await this.loadBranch();

      var thisBranch = await allBranches.find(a => a.code === branch_th);

      this.currentBranchId = await thisBranch.branchId;

      return thisBranch;
    },
    async loadProduct() {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem",
          this.$store.state.auth.apiHeader
        );
        if (response.status === 200) {
          this.branchStock = await response.data;
        }
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },
    async loadStock(branchId) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem/branch/" + branchId,
          this.$store.state.auth.apiHeader
        );
        if (response.status === 200) {
          this.branchStock = await response.data;
        }
      } catch (error) {
        this.noticeError(error);
        // console.log("error", error);
      }

      return await response.data;
    },
    async loadBranch() {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/branch",
          this.$store.state.auth.apiHeader
        );
        // this.noticeSuccess("ทำการบันทึกสำเร็จ");
        if (response.status === 200) {
          this.branches = await response.data;
        }
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },
    selectAll: function(event) {
      // Workaround for Safari bug
      // http://stackoverflow.com/questions/1269722/selecting-text-on-focus-using-jquery-not-working-in-safari-and-chrome
      setTimeout(function() {
        event.target.select();
      }, 0);
    },
    computeBailDiscount(newValue) {
      if (
        this.bailTotal != 0 &&
        this.bailTotal != undefined &&
        this.bailTotal != null
      )
        this.bailDiscount = this.bailTotal * (newValue / 100);
    },
    computeRentalDiscount(newValue) {
      if (
        this.rentalPriceTotal != 0 &&
        this.rentalPriceTotal != undefined &&
        this.rentalPriceTotal != null
      )
        this.rentalDiscount = this.rentalPriceTotal * (newValue / 100);
    },
    computeDiscountPercent() {

      if (
        this.rentalPriceTotal === 0 ||
        this.rentalPriceTotal === undefined ||
        this.rentalPriceTotal === null
      )
        this.rentalDiscountPercent = 0;
      else{
        var exVat = 0;
        exVat = this.beforeVat_final(this.rentalPriceTotal, this.rentalDiscount);
        if(this.taxInfo.vatSystem === true && this.taxInfo.inVat === true ){
             this.rentalDiscountPercent = (this.rentalDiscount / exVat) * 100;


        }else{
          this.rentalDiscountPercent =
          (this.rentalDiscount / this.rentalPriceTotal) * 100;
        }

      }
      if (
        this.bailTotal === 0 ||
        this.bailTotal === undefined ||
        this.bailTotal === null
      )
        this.bailDiscountPercent = 0;
      else
        this.bailDiscountPercent = (this.bailDiscount / this.bailTotal) * 100;
    },
    confirmDetail() {
      this.$router.push({
        path: "/backoffice/order-detail/" + this.orderInfo.orderNumber
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setFocus: function() {
      // Note, you need to add a ref="search" attribute to your input.
      // this.$refs.addcart.$el.focus();
    },

    lineTotal: function(rentalPrice, bail) {
      if (!Number.isFinite(rentalPrice)) rentalPrice = 0;
      if (!Number.isFinite(bail)) rentalPrice = 0;
      return rentalPrice + bail;
    },
    removeItem: function(indextr) {
      this.checkRentalList = false;

      this.displayItems[indextr].reservedQuantity = 1;
      this.displayItems[indextr].notAvailable = false;
      this.displayItems.splice(indextr, 1);
    },
    setSelect: function() {
      event.target.setSelectionRange(0, this.addtocart.length);
    },
    // setPhoneSelect: function() {
    //   event.target.setSelectionRange(0, this.customerPhone.length);
    // },
    // **********************************************************
    // ****************** CALCULATE TOTAL ***********************
    // **********************************************************
    calculateTotal() {
      this.rentalPriceTotal = 0;
      this.bailTotal = 0;
      this.grandTotal = 0;

      for (var i = 0; i < this.displayItems.length; i++) {
        this.rentalPriceTotal =
          this.rentalPriceTotal +
          this.displayItems[i].rentalPrice *
            this.displayItems[i].reservedQuantity;

        this.bailTotal =
          this.bailTotal +
          this.displayItems[i].bail * this.displayItems[i].reservedQuantity;
      }

      this.grandTotal =
        this.grandTotal + this.rentalPriceTotal + this.bailTotal;

      this.computeDiscountPercent();
      // **********************************************************
      this.calculatePromotion();
    }
    // **********************************************************
  },

  watch: {
    async getCurrentBranch(){
        // await this.reload();
        // await this.resetAll();
        this.$vs.loading();
        this.checkRentalList = false;
        this.startDate = null;
        this.endDate = null;

        this.currentBranchId = this.getCurrentBranch.branchId;
        this.thisBranch = this.getCurrentBranch;
        this.rentalDate = {};

        // this.calend

        // var bra = this.$store.state.auth.myBranches.filter(
        //   b => b.branchId === value
        // );
        // this.currentBranchId = value;


        await this.init(this.getCurrentBranch);
        setTimeout(() => {
          this.$vs.loading.close();
        }, 300);


    },
    customerPhone(value){
      if(value === "" || value === undefined || value === null){
        this.customerName = "";
        this.customerAddress = "";
      }
    }
    ,
    rentalDate: {
      handler(value) {
        // console.log("@@@rentalDate >>>>  ", value);

        if (Object.entries(value).length === 0 || value === null) {
          this.startDate = null;
          this.endDate = null;
        } else {
          this.startDate = this.formatDate(value.start);
          this.endDate = this.formatDate(value.end);
        }
      },
      deep: true
    },
    // branchRadio: async function(value) {
    //   this.$vs.loading({ type: "radius" });
    //   this.checkRentalList = false;
    //   this.startDate = null;
    //   this.endDate = null;

    //   var bra = this.$store.state.auth.myBranches.filter(
    //     b => b.branchId === value
    //   );
    //   this.currentBranchId = value;

    //   // this.thisBranch = this.$store.state.auth.currentBranch;

    //   await this.init(bra[0]);
    //   setTimeout(() => {
    //     this.$vs.loading.close();
    //   }, 500);
    // },
    startDate: function() {
      // this.endDate = value;
      this.checkRentalList = false;
      // *** BEGIN 2021-01-19
      this.activeBtn = false;
      // *** END 2021-01-19
    },
    endDate: function() {
      this.checkRentalList = false;
      // *** BEGIN 2021-01-19
      this.activeBtn = false;
      // *** END 2021-01-19
    },
    customerInfo: function(newphone) {
      var ac = this.customers.find(x => x.phone.includes(newphone));
      // console.log("Customer info >>> ", ac);
      if (ac) {
        this.customerName = ac.name;
        this.customerAddress = ac.address;
        this.customerPhone = ac.phone;
      }
    },

    rentalDiscount: function() {
      this.computeDiscountPercent();
    },
    bailDiscount: function() {
      this.computeDiscountPercent();
    },
    // addtocart: function(newvalue) {
    //   console.log("newvalue >>> ",newvalue);
    //   this.checkRentalList = false;
    //   // *** BEGIN 2021-01-19
    //   this.activeBtn = false;
    //   // *** END 2021-01-19

    //   var newItem = this.itemsInBranch.filter( x => x.code === newvalue);

    //   console.log("itemsInBranch >>> ",this.itemsInBranch);

    //   if(newItem > 0){
    //       console.log("FOUND !!!!  >>> ",newvalue);
    //       return newItem[0];
    //   }

    //   console.log("FOUND !!!! newItem  in itemsInBranch >>> ",newItem);

    //   // var newItem = this.itemsInBranch.find(function(ele) {
    //   //   if (ele.code === newvalue) {

    //   //     console.log("FOUND !!!!  >>> ",newvalue);
    //   //     return ele;
    //   //   }
    //   // });

    //   // console.log("newItem >>> ", newItem);
    //   // console.log("this.displayItems >>> ", this.displayItems);

    //   if (newItem > 0) {
    //     event.target.setSelectionRange(0, this.addtocart.length);

    //     var foundindex = this.displayItems.findIndex(
    //       x => x.code === newItem.code
    //     );

    //     if (foundindex < 0) this.displayItems.push(newItem);
    //     else {
    //       this.displayItems[foundindex].reservedQuantity++;
    //       this.calculateTotal();
    //     }



    //     this.addtocart = "";

    //   }
    // },

    addtocart: function(newvalue) {
      this.checkRentalList = false;
      // *** BEGIN 2021-01-19
      this.activeBtn = false;
      // *** END 2021-01-19
      var newItem = this.itemsInBranch.find(function(ele) {
        if (ele.code === newvalue) {
          return ele;
        }
      });

      // console.log("newItem >>> ", newItem);

      if (newItem) {
        event.target.setSelectionRange(0, this.addtocart.length);

        var foundindex = this.displayItems.findIndex(
          x => x.code === newItem.code
        );

        if (foundindex < 0) this.displayItems.push(newItem);
        else {
          this.displayItems[foundindex].reservedQuantity++;
          this.calculateTotal();
        }


        this.addtocart = "";
      }
    },



    displayItems: {
      handler() {
        // const dis = this.displayItems.map(item => item.reservedQuantity);
        // console.log('this.displayItems >>> ',dis);

        // *** BEGIN 2021-01-19
        // this.activeBtn = false;
        // *** END 2021-01-19

        this.calculateTotal();
      },
      deep: true
    }
  }
};
</script>

<style>
input {
  width: 100%;
}

.vs-col {
  flex-wrap: wrap;
}
.row-form {
  margin-bottom: 30px;
}
.form-label {
  font-weight: bold;
}
.clear {
  display: none;
}

.vs-table--not-data{
  display: none;
}

.notAvailable {
  background: #e95455;
  border: 1px solid #9e0619;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}
</style>
